import React from 'react';

const Catalog = () => {

	return (
		<div className="wrapper">
			CatalogCatalogCatalog
		</div>
	);
}

export default Catalog;
